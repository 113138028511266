.sc-aXZVg{
    display: none!important;
}
.sc-gEvEer,.ffXrxx{
z-index: 10000!important;
}


/* ////////////////////////////////  */


  .et_pb_contact_form_label {
      display: block;
      color: black;
      font-weight: bold;
    letter-spacing: 1.2px;
      font-size: 18px;
      padding-bottom: 5px;
  }
  input[id="et_pb_contact_brand_file_request_0"] {
      display: none;
  }
  label[for="et_pb_contact_brand_file_request_0"] {
      background: #fff;
      height: 145px;
      background-image: url('https://www.svgrepo.com/show/9488/cloud-upload-signal.svg');
      background-repeat: no-repeat;
      background-position: top 18px center;
      position: absolute;
    background-size: 7%;
      color: transparent;
    margin: auto;
      width: 450px;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
      border: 1px solid #a2a1a7;
      box-sizing: border-box;
  }
  label[for="et_pb_contact_brand_file_request_0"]:before {
      content: "Drag and Drop a file here";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      color: #202020;
      font-weight: 400;
      left:0;
      right:0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
  }
  label[for="et_pb_contact_brand_file_request_0"]:after {
      display: block;
      content: 'Browse';
      background: #16a317;
      width: 86px;
      height: 27px;
    line-height: 27px;
      position: absolute;
      bottom: 19px;
      font-size: 14px;
      color: white;
      font-weight: 500;
      left:0;
      right:0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
  }
  label[for="et_pb_contact_brand_request_0"]:after {
      content: " (Provide link or Upload files if you already have guidelines)";
      font-size: 12px;
      letter-spacing: -0.31px;
      color: #7a7a7a;
      font-weight: normal;
  }
  label[for="et_pb_contact_design_request_0"]:after {
      content: " (Provide link or Upload design files)";
      font-size: 12px;
      letter-spacing: -0.31px;
      color: #7a7a7a;
      font-weight: normal;
  }
  label[for="et_pb_contact_brand_file_request_0"].changed, label[for="et_pb_contact_brand_file_request_0"]:hover {
    background-color: #e3f2fd;
  }
  label[for="et_pb_contact_brand_file_request_0"] {
    cursor: pointer;
    transition: 400ms ease;
  }
  .file_names {
      display: block;
      position: absolute;
      color: black;
      left: 0;
      bottom: -30px;
      font-size: 13px;
      font-weight: 300;
  }
  .file_names {
    text-align: center;
  }
/* doc file formate  */


@media print {



  #print-content {
    display: block;
    padding: 10px;
    background-color: white;
    background-image: none;
    color: black;
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  #print-content * {
    display: block !important;
  }
}
.searchLoan, .searchSelect{
  width: 100%;
  padding: 10px!important;
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}


/* navbar  */
/* .has-sub-menu:hover .has-sub-menu.sub-menu{
  display: -webkit-box;
  display: flex;
} */

.sub-menu li{
  cursor: pointer;
}


/* .nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box{
  transition: all 0.3s ease;
  top: 81px;
  opacity: 1;
  visibility: visible;
} */